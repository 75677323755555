import styled from "@emotion/styled";
import Link from "next/link";
import Spacer from "components/atoms/Spacer";
import RelativeTime from "components/atoms/RelativeTime";
import CompanyLogo from "components/molecules/CompanyLogo";
import { LatestNews } from "./fetchers";

type Props = {
  newsData: LatestNews[];
};
export const LatestSection = ({ newsData }: Props) => {
  return (
    <Wrapper>
      <Header>
        <h3>Latest news</h3>
        <Link href={`/news`}>
          <SeeMore>SEE ALL NEWS</SeeMore>
        </Link>
      </Header>
      <ListItems>
        {newsData
          ? newsData.map((news) => (
              <Link
                href={`/event/[id]`}
                as={`/event/${news.id}`}
                key={`job-listing-${news.id}`}
                passHref
              >
                <ListItemWrapper key={`news-item-${news.id}`}>
                  <Logo>
                    <CompanyLogo url={news.imgUrl} name={news.companyName} />
                  </Logo>
                  <ItemContent>
                    <Description>{news.title}</Description>

                    <CompanyName>
                      <RelativeTime noWrap since={news.published} /> |{" "}
                      {news.companyName}
                    </CompanyName>
                  </ItemContent>
                </ListItemWrapper>
              </Link>
            ))
          : [1, 2, 3, 4, 5].map((i) => (
              <ListItemWrapper key={i}>
                <Logo>
                  <Spacer animate height={32} />
                </Logo>
                <ItemContent>
                  <Description>
                    <Spacer animate height={12} fromEdge={10} />
                  </Description>

                  <CompanyName>
                    <Spacer animate height={10} fromEdge={100} />
                  </CompanyName>
                </ItemContent>
              </ListItemWrapper>
            ))}
      </ListItems>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 60%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  @media (max-width: 769px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
  color: #24252a;

  h3 {
    font-size: 28px;
    font-weight: 600;
    margin: 10px 0;
  }
`;

const SeeMore = styled.a`
  font-size: 16px;
  margin-top: 10px;
  color: #6d7278;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease;
  &:hover {
    color: #24252a;
  }
`;

const ListItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 10px 10px 0;
  border-top: solid 1px #d2d2d2;
  cursor: pointer;
`;
const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
`;
const Description = styled.p`
  width: 100%;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 18px;
  margin: 0 0 10px 0;
`;
const CompanyName = styled.span`
  font-size: 16px;
  line-height: 1.25;
  opacity: 0.5;
`;
const Logo = styled.div`
  height: 42px;
  width: 42px;
  img {
    height: 100%;
    width: 100%;
  }
`;
