import styled from "@emotion/styled";
import { css } from "@emotion/css";
import { keyframes } from "@emotion/react";

const pulse = keyframes`
    0% {
        background-color: rgba(232, 237, 242, 0.3)
    }
    25% {
        background-color: rgba(232, 237, 242, 0.6)
    }
    50% {
        background-color: rgba(232, 237, 242, 1)
    }
    75% {
        background-color: rgba(232, 237, 242, 0.6)
    }
    100% {
        background-color: rgba(232, 237, 242, 0.3)
    }
`;

const pulseDarker = keyframes`
    0% {
        background-color: rgba(41, 41, 41, 0.1)
    }
    25% {
        background-color: rgba(41, 41, 41, 0.2)
    }
    50% {
        background-color: rgba(41, 41, 41, 0.3)
    }
    75% {
        background-color: rgba(41, 41, 41, 0.2)
    }
    100% {
        background-color: rgba(41, 41, 41, 0.1)
    }
`;

const animation = (_props) => css`
  ${pulse} 2s infinite ease-in-out
`;

const animationDarker = (_props) => css`
  ${pulseDarker} 2s infinite ease-in-out
`;

export interface SpacerProps {
  animate?: boolean;
  circle?: boolean;
  color?: string;
  darker?: boolean;
  fromEdge?: number;
  height?: number;
  margin?: string;
  offset?: number;
  width?: string;
}

const Spacer = styled.span<SpacerProps>`
  width: ${(props) =>
    props.width ? props.width : `calc(100% - ${props.fromEdge || 0}px)`};
  height: ${(props) => props.height || 15}px;
  display: inline-block;
  background-color: ${(props) =>
    props.color || props.darker
      ? "rgba(41, 41, 41, 0.1)"
      : "var(--light-grey)"};
  border-radius: ${(props) => (props.circle ? "50%" : "none")};
  margin: ${(props) => props.margin || "0 0 16px"};
  animation: ${(props) =>
    props.animate ? (props.darker ? animationDarker : animation) : ""};
  animation-delay: ${(props) => `${props.offset * 0.3}s` || "0"};
`;

export default Spacer;
