import { memo, PropsWithChildren, ReactElement } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Entry } from "contentful";
import Head from "next/head";
import { CONTENT_TYPES } from "../../../lib/contentful";
import Header from "components/Header/Header";
import { IPage } from "./types";
import { Main } from "../Main";
import Footer from "components/organisms/Footer";
import CallToAction from "../CallToAction";
import Action from "../Action";
import DetailsCard from "../DetailsCard";
import BlogPosts from "../BlogPosts";
import Group from "../Group";
import AddressField from "../AddressField";
import Heading from "../Heading";
import Section from "../Section";
import { ISection } from "../Section/types";
import RichText from "../RichText";
import IconCard from "../IconCard";
import ImageOverflowSection from "../ImageOverflowSection";
import Testimonial from "../CustomerFeedback/Card";
import TestimonialsSection from "../CustomerFeedback/Section";
import ExplainerBox from "../ExplainerBox";
import Table from "../Table";
import Nav from "../Nav";
import Category from "../Category";
import BuyButton from "../BuyButton";
import ContactUsForm from "../ContactUsForm";
import TrustedByLogos from "../TrustedBy";
import TwoThirdsColumns from "../TwoThirdsColumns";
import ThreeColumns from "../ThreeColumns";
import FooterOverflowSection from "../FooterOverflowSection";
import DynamicSection from "../DynamicSection";
import { SideNav } from "components/SideNav";
import { IBuyButton } from "../BuyButton/types";
import LaptopPreview from "../LaptopPreview";
import dynamic from "next/dynamic";
import HeroBox from "../HeroBox";

const ClientSideBookDemoModal = dynamic(
  () => import("components/BookDemoModal"),
  {
    ssr: false,
  }
);

export type Props = {
  page: Entry<IPage>;
  blogPosts?: any;
  pageNumber?: number;
  preview: boolean;
};

export function Page({
  page,
  preview,
  blogPosts,
  pageNumber,
  children,
}: PropsWithChildren<Props>): JSX.Element {
  if (!page) {
    return <div></div>;
  }
  const { fields, sys } = page;
  const PageSection = ({
    text,
    items,
    columns,
    narrow,
    action,
    backgroundColor,
    textColor,
    className,
  }: ISection) => {
    return (
      <Section
        className={className || "page-section"}
        columns={columns}
        narrow={narrow}
        backgroundColor={backgroundColor}
        textColor={textColor}
      >
        {text && (
          <header className="section-header">
            {documentToReactComponents(text)}
          </header>
        )}
        <div className="grid">{items && items.map(renderItem)}</div>
        {action && (
          <Action
            text={action.fields.text}
            url={action.fields.url}
            primary={action.fields.primary}
            openInNewTab={action.fields.openInNewTab}
            icon={action.fields.icon}
          />
        )}
      </Section>
    );
  };
  const renderItem = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item: Entry<any>, // TODO: Create distinct types for the different entries
    idx: number
  ): ReactElement => {
    if (
      !(item && item.sys && item.sys.contentType && item.sys.contentType.sys)
    ) {
      return null;
    }

    switch (item.sys.contentType.sys.id) {
      case CONTENT_TYPES.HEADING:
        return (
          <Heading key={item.sys.id}>
            <h1>{(item as Entry<{ title: string }>).fields.title}</h1>
          </Heading>
        );
      case CONTENT_TYPES.HERO_BOX:
        return <HeroBox key={item.sys.id} {...item.fields} />;
      case CONTENT_TYPES.SECTION:
        return <PageSection key={item.sys.id} {...item.fields} />;
      case CONTENT_TYPES.ICON_TEXT:
        return <IconCard key={item.sys.id} {...item.fields} />;
      case CONTENT_TYPES.EXPLAINER_BOX:
        return <ExplainerBox key={item.sys.id} {...item.fields} idx={idx} />;
      case CONTENT_TYPES.CALL_TO_ACTION:
        return <CallToAction key={item.sys.id} {...item.fields} />;
      case CONTENT_TYPES.DETAILS_CARD:
        return (
          <DetailsCard
            key={item.sys.id}
            title={item.fields.title}
            description={item.fields.description}
            img={item.fields.img.fields.file.url}
            imgDesc={item.fields.img.fields.description}
          />
        );
      case CONTENT_TYPES.CUSTOMER_TESTIMONIALS:
        return <TestimonialsSection key={item.sys.id} {...item.fields} />;
      case CONTENT_TYPES.TESTIMONIAL:
        return <Testimonial key={item.sys.id} {...item.fields} />;
      case CONTENT_TYPES.CATEGORY:
        return <Category key={item.sys.id} {...item.fields} />;
      case CONTENT_TYPES.TABLE:
        return <Table key={item.sys.id} {...item.fields} />;
      case CONTENT_TYPES.TRUSTED_BY_LOGOS:
        return <TrustedByLogos key={item.sys.id} {...item.fields} />;
      case CONTENT_TYPES.EDUCATIONAL_VIDEOS_GROUP:
        return <Group key={item.sys.id} {...item} />;
      case CONTENT_TYPES.DYNAMIC_SECTION:
        return <DynamicSection key={item.sys.id} {...item} />;
      case CONTENT_TYPES.CUSTOM_CONTROL:
        if (item.fields.controlSlug === "insights-navigation") {
          return <Nav currentPage={fields.url} />;
        }
        if (item.fields.controlSlug === "contact-us-form") {
          return <ContactUsForm />;
        }
        return null;
      case CONTENT_TYPES.TWO_THIRDS_COLUMNS:
        return (
          <TwoThirdsColumns key={item.sys.id} reverse={item.fields.reverse}>
            <RichText body={item.fields.leftHandSide} />
            <RichText body={item.fields.rightHandSide} />
          </TwoThirdsColumns>
        );
      case CONTENT_TYPES.THREE_COLUMNS:
        return (
          <ThreeColumns key={item.sys.id}>
            {item.fields.items.map(renderItem)}
          </ThreeColumns>
        );
      case CONTENT_TYPES.FOOTER_OVERFLOW_SECTION:
        return (
          <FooterOverflowSection
            key={item.sys.id}
            reverse={item.fields.reverse}
            body={item.fields.body}
            image={item.fields.image}
          />
        );
      case CONTENT_TYPES.BUY_BUTTON: {
        const buyButton = item as Entry<IBuyButton>;
        return (
          <BuyButton
            planId={buyButton.fields.planId}
            buyText={buyButton.fields.buyText}
            currencyCode={buyButton.fields.currencyCode}
          />
        );
      }
      case CONTENT_TYPES.BLOG_POSTS:
        return (
          blogPosts && (
            <BlogPosts
              key={item.sys.id}
              posts={{ ...blogPosts }}
              columns={3}
              pageNumber={pageNumber}
              {...item.fields}
            />
          )
        );
      case CONTENT_TYPES.ADDRESS_FIELD:
        return (
          <AddressField
            title={item.fields.title}
            body={documentToReactComponents(item.fields.body)}
          />
        );
      case CONTENT_TYPES.LAPTOP_PREVIEW:
        return <LaptopPreview image={item.fields.image} />;

      case CONTENT_TYPES.IMAGE_OVERFLOW_SECTION:
        return <ImageOverflowSection key={item.sys.id} {...item.fields} />;

      default:
        return null;
    }
  };

  if (!(fields && sys)) {
    return null;
  }
  const { items, title, hasSidebar } = fields;

  return (
    <>
      <div style={{ display: "grid", gridTemplateAreas: `"hd" "mn" "ft"` }}>
        <Head>
          <title>{title}</title>
        </Head>
        <Header preview={preview} />
        <Main>
          {hasSidebar && <SideNav />}
          {children}
          {items && items.map(renderItem)}
        </Main>
        <ClientSideBookDemoModal />
        <Footer />
      </div>
    </>
  );
}

export default memo(Page);
