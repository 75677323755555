import styled from "@emotion/styled";

export type Props = {
  title: string;
  body: any;
};

const Div = styled.div`
  margin-bottom: 8px;
  padding: 8px 0;
`;

function AddressField(props: Props) {
  return (
    <Div>
      <div className="title">{props.title}</div>
      <div>{props.body}</div>
    </Div>
  );
}

export default AddressField;
