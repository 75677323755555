import { memo } from "react";
import styled from "@emotion/styled";
import { Group } from "../Group";

const Section = styled.section`
  grid-column: 1 / 10;

  .description {
    display: block;
    border-bottom: 1px solid #979797;
    padding: 0 0 18px 0;
  }

  .measure {
    max-width: 28em;
    text-align: left;
  }
`;

export type Props = {
  title: string;
  description: string;
  groups: any[];
};
export const Category = ({ title, description, groups = [] }: Props) => {
  return (
    <Section>
      <h1>{title}</h1>
      <div className="description">
        <div className="measure">{description}</div>
      </div>
      {groups && groups.map && groups.map(Group)}
    </Section>
  );
};

export default memo(Category);
