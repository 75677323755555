import { memo } from "react";
import { PageNavigator } from "../PageNavigator";
import Cards from "../Cards";
import { URLS } from "../constants";
import { IBlogPosts } from "./types";

export type Props = {
  posts: IBlogPosts;
  pageNumber?: number;
  numberOfBlogPosts: number;
  paged?: boolean;
};

const BlogPosts = ({
  posts: { items, total },
  numberOfBlogPosts,
  pageNumber = 1,
  paged,
}: Props) => {
  const numberOfPages = Math.ceil(total / numberOfBlogPosts);
  return (
    <>
      <Cards items={items} page={pageNumber} firstPrimary={paged} />
      <PageNavigator
        page={pageNumber - 1}
        numberOfPages={numberOfPages || 1}
        href={URLS.ARTICLES}
      />
    </>
  );
};

export default memo(BlogPosts);
