import { memo } from "react";
import { Grid } from "../Grid";
import { IHeroBox } from "./types";
import { ContentWrapper, Ilustration, Section } from "./style";
import TitleAction from "../TitleAction";

type Props = IHeroBox;

export function HeroBox({
  header,
  subheader,
  description,
  textColor,
  backgroundColor,
  actions,
  media,
  mediaFullHeight,
  backgroundImg,
}: Props): JSX.Element {
  const mediaSrc =
    media && media.fields && media.fields.file && media.fields.file.url;
  return (
    <Section
      backgroundImg={backgroundImg}
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      <Grid style={{ alignItems: "center" }}>
        <ContentWrapper>
          <TitleAction
            primary
            subHeading={subheader}
            heading={header}
            actions={actions}
            description={description}
          />
        </ContentWrapper>
        {typeof mediaSrc === "string" && (
          <Ilustration src={mediaSrc} fullHeight={mediaFullHeight} />
        )}
      </Grid>
    </Section>
  );
}

export default memo(HeroBox);
