import { CustomerFeedback } from "../types";
import CustomerFeedbackCard from "../Card";
import { CFSectionRoot } from "../styles";
import Slider from "../Slider";
export type Props = {
  /**
   * Forced to be length 3, but could be adapted to be a carousel in the future
   */
  testimonials?: [CustomerFeedback, CustomerFeedback, CustomerFeedback];
};
/**
 * Display customer feedback on the homepage
 */
const CustomerFeedbackSection = (props: Props): JSX.Element => {
  if (!props.testimonials) return null;
  return (
    <CFSectionRoot>
      {/* <h1>What our customers say</h1> */}
      <div className="cards">
        {props.testimonials.map((feedback, i) => (
          <CustomerFeedbackCard
            key={`customer-feedback-card-${i}`}
            quote={feedback.fields.quote}
            personsName={feedback.fields.personsName}
            image={feedback.fields.image}
          />
        ))}
      </div>
      <Slider testimonials={props.testimonials} />
    </CFSectionRoot>
  );
};

export default CustomerFeedbackSection;
