import styled from "@emotion/styled";
import useSWR from "swr";
import { Content } from "./Content";
import { LatestSection } from "./LatestSection";
import { latestNewsFetcher } from "./fetchers";

type ElementProps = {
  primary?: boolean;
};
type FieldsType = {
  name: string;
  heading: string;
  description: string;
  primary: boolean;
};
export type Props = {
  fields: FieldsType;
};

const DynamicSection = ({ fields }: Props) => {
  const { name, heading, description, primary } = fields;
  const { data } = useSWR(`/v5-powersearch/search`, latestNewsFetcher);

  return (
    <Container>
      <Wrapper primary={primary}>
        <Content
          name={name}
          heading={heading}
          description={description}
          primary={primary}
        />
      </Wrapper>
      <Wrapper>
        <LatestSection newsData={data} />
      </Wrapper>
    </Container>
  );
};

export default DynamicSection;

const Container = styled.section`
  width: 100%;
  height: 600px;
  display: flex;

  flex-direction: row;
  @media (max-width: 769px) {
    flex-direction: column;
    height: auto;
  }
`;
const Wrapper = styled.div<ElementProps>`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.primary ? props.theme.colors.highlight : "#ededed"};
  @media (max-width: 769px) {
    padding: 30px;
    width: 100%;
  }
`;
