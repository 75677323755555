import { memo } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document } from "@contentful/rich-text-types";
import styled from "@emotion/styled";

import { Action } from "../Action";
import FeatureCard from "../FeatureCard/Card";
import { Testimonial } from "../Testimonial";
import LaptopPreview from "../LaptopPreview";

export type Props = {
  body: Document;
  className?: string;
};

const Div = styled.div`
  text-align: left;
  p {
    max-width: 34em;
    color: var(--dark-grey);
    font-size: 18px;
    line-height: 1.6;
  }

  @media ${({ theme }) => theme.breakpoints.laptop} {
    text-align: center;

    h3 {
      margin: 0 auto;
    }
  }

  @media ${({ theme }) => theme.breakpoints.mobile} {
    h2 {
      font-size: 37px;
    }
  }
`;

export function RichText({ body, className }: Props): JSX.Element {
  const options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ENTRY]: (node: Document) => {
        if (!(node && node.data && node.data.target && node.data.target.sys)) {
          return;
        }
        switch (node.data.target.sys.contentType.sys.id) {
          case "testimonial":
            return <Testimonial {...node.data.target.fields} />;
          case "featureCard":
            return <FeatureCard {...node.data.target.fields} />;
          case "action":
            return <Action {...node.data.target.fields} />;
          case "laptopPreview":
            return <LaptopPreview {...node.data.target.fields} />;
          default:
            return null;
        }
      },
    },
  };
  return (
    <Div className={className}>
      <div data-testid="rich-text-area" className="inner">
        {documentToReactComponents(body, options)}
      </div>
    </Div>
  );
}

export default memo(RichText);
