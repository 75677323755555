/* eslint-disable react/display-name */
import { memo } from "react";
import styled from "@emotion/styled";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Testimonial } from "../Testimonial";

const Img = styled.img<Pick<Props, "idx">>`
  justify-self: center;
  align-self: center;
  padding: 7.5% 0;
  @media (min-width: 769px) {
    grid-row: ${(props) => props.idx + 1};
    grid-column: ${(props) => (props.idx % 2 === 1 ? "1 / 2" : "2 / 3")};
    justify-self: ${(props) => (props.idx % 2 === 1 ? "end" : "start")};
  }
`;

const Div = styled.div<Pick<Props, "idx">>`
  padding: 0 20px;
  text-align: left;
  justify-self: center;
  align-self: center;
  font-size: 16px;

  @media (min-width: 769px) {
    padding: 0;
    max-width: 25em;
    text-align: ${(props) => (props.idx % 2 === 1 ? "left" : "right")};
    grid-column: ${(props) => (props.idx % 2 === 1 ? "2 / 3" : "1 / 2")};
    grid-row: ${(props) => props.idx + 1};
    justify-self: ${(props) => (props.idx % 2 === 0 ? "end" : "start")};
  }
`;

export type Props = {
  body: any;
  image?: {
    fields?: {
      file?: {
        url: string;
      };
    };
  };
  idx: number;
};

export function ExplainerBox({ body, image, idx }: Props) {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (!node?.data?.target?.sys) {
          return;
        }
        switch (node.data.target.sys.contentType.sys.id) {
          case "testimonial":
            return <Testimonial {...node.data.target.fields} />;
          default:
            return null;
        }
      },
    },
  };
  return (
    <>
      <Img
        src={
          image && image.fields && image.fields.file
            ? image.fields.file.url
            : ""
        }
        alt=""
        idx={idx}
      />
      <Div idx={idx}>{documentToReactComponents(body, options)}</Div>
    </>
  );
}

export default memo(ExplainerBox);
