/**
 *
 * RelativeTime
 *
 */

import styled from "@emotion/styled";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInCalendarWeeks from "date-fns/differenceInCalendarWeeks";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import * as Sentry from "@sentry/browser";

const getEstimatedTime = (since: string) => {
  if (!since) {
    return "";
  }
  try {
    const days = differenceInCalendarDays(global.Date.now(), parseISO(since));
    const weeks = differenceInCalendarWeeks(global.Date.now(), parseISO(since));
    const dateDistance = formatDistance(global.Date.now(), parseISO(since));
    const date = format(parseISO(since), "dd/MM/yyyy");
    if (days <= 0) return "Today";

    if (weeks === 0) return `${dateDistance} ago`;

    if (weeks >= 1) return date;
  } catch (error) {
    Sentry.captureException(error);
  }

  return "";
};

const Time = styled.time<{
  light?: boolean;
  display?: string;
  noWrap?: boolean;
}>`
  color: ${(props) => (props.light ? "var(--light-text)" : "inherit")};
  display: ${(props) => props.display || "inline-block"};
  white-space: ${(props) => (props.noWrap ? "nowrap" : "auto")};
`;

const RelativeTime = ({ since, light, display, noWrap }: RelativeTimeProps) => (
  <Time light={light} display={display} noWrap={noWrap}>
    {getEstimatedTime(since)}
  </Time>
);

export interface RelativeTimeProps {
  since: any;
  display?: string;
  light?: boolean;
  noWrap?: boolean;
}

export default RelativeTime;
