import { memo } from "react";
import styled from "@emotion/styled";
import Action from "../Action";

export type Props = {
  headline: string;
  subheader: string;
  action?: {
    fields: any;
  };
};

export const CallToAction = ({ headline, subheader, action }: Props) => {
  return (
    <Section>
      <h2>{headline}</h2>
      <div className="subheader">{subheader}</div>
      {action && <Action {...action.fields} />}
    </Section>
  );
};

const Section = styled.section(
  ({ theme }) => `
    background-color: #24252a;
    color: #fff;
    text-align: center;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    font-size: 18px;
    padding: 40px 20px;

    .subheader {
      padding: 0 0 20px;
    }
  `
);

export default memo(CallToAction);
