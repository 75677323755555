import { Asset } from "contentful";
import styled from "@emotion/styled";
import HighlightTitle from "../HighlightTitle";

export type Props = {
  companyLogo: Asset[];
};
const TrustedBy = ({ companyLogo }: Props) => {
  return (
    <TrustedByRoot>
      <div className="logos">
        {companyLogo.map((logo: Asset) => (
          <div key={`trusted-by-${logo.fields.title}`} className="logo-wrapper">
            <img src={logo.fields.file.url} alt={logo.fields.title} />
          </div>
        ))}
      </div>
    </TrustedByRoot>
  );
};

const TrustedByRoot = styled.section`
  .logos {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .logo-wrapper {
    width: 200px;
    height: 150px;
    margin: 0 20px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

export default TrustedBy;
