import { Asset } from "contentful";
import styled from "@emotion/styled";

export type FeatureCardProps = {
  title: string;
  content: string;
  icon: Asset;
};

const FeatureCard = ({
  title,
  content,
  icon,
}: FeatureCardProps): JSX.Element => {
  return (
    <CardWrapper>
      <IconWrapper>
        <img
          src={
            icon && icon.fields && icon.fields.file ? icon.fields.file.url : ""
          }
          alt={(icon && icon.fields && icon.fields.description) || ""}
        />
      </IconWrapper>

      <ContentWrapper>
        <Heading>{title}</Heading>
        <Paragraph>{content}</Paragraph>
      </ContentWrapper>
    </CardWrapper>
  );
};

export default FeatureCard;

const CardWrapper = styled.div`
  min-height: 80px;
  margin: 0 10px 21px 10px;
  padding: 29px 60px;
  object-fit: contain;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
    align-items: center;
    padding: 24px;
    min-height: 338px;
    justify-content: center;
  }
`;
const IconWrapper = styled.div`
  width: 100px;
  min-height: 100%;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary};
  @media ${({ theme }) => theme.breakpoints.mobile} {
    width: 100%;
    min-height: 50px;
    margin-right: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    align-items: center;
    text-align: center;
    width: 100%;
  }
`;

const Heading = styled.h4`
  font-size: 24px;
  margin: 0;
`;

const Paragraph = styled.p`
  margin: 8px 0 0;
  font-size: 16px;
  text-align: left;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    text-align: center;
  }
`;
