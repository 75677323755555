import { Asset } from "contentful";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Action from "components/Contentful/Action";

export type TitleActionProps = {
  primary?: boolean;
  subHeading: string;
  heading: string;
  description?: string;
  actions?: {
    fields: {
      text: string;
      url: string;
      color?: string;
      primary: boolean;
      icon: Asset;
      outlined?: boolean;
      staticAction?: boolean;
    };
  }[];
  className?: string;
};

const TitleAction = ({
  subHeading,
  heading,
  description,
  actions,
  primary,
  className,
}: TitleActionProps): JSX.Element => {
  return (
    <Container className={className}>
      <SubHeading>{subHeading}</SubHeading>
      <Heading as={primary ? "h1" : null}>{heading}</Heading>
      {description && <Paragraph>{description}</Paragraph>}
      {actions && (
        <ActionsWrapper>
          {actions.map(
            (
              { fields: { text, url, primary, icon, color, staticAction } },
              idx
            ) => {
              return (
                <Action
                  key={`action-${text}-${idx}`}
                  url={url}
                  text={text}
                  primary={primary}
                  icon={icon}
                  staticAction={staticAction}
                  openInNewTab={true}
                  color={color}
                />
              );
            }
          )}
        </ActionsWrapper>
      )}
    </Container>
  );
};

export default TitleAction;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-right: 30px;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    width: 100%;
    max-width: 100%;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 0px;
    padding: 0 10px;
  }
`;

const SubHeading = styled.h5(
  ({ theme }) => css`
    color: ${theme.colors.contentful.subheading};
    margin: 0 0 10px;
    font-size: 20px;
    @media ${theme.breakpoints.mobile} {
      font-size: 18px;
    }
  `
);

const Heading = styled.h3`
  font-size: 42px;
  margin: 0 0 10px;
  text-align: start;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 32px;
    text-align: center;
  }
`;
const Paragraph = styled.p`
  margin: 0 0 10px;
  text-align: start;
  max-width: 25em;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    text-align: center;
  }
`;
const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;

  a {
    margin-right: 30px;
  }

  @media ${({ theme }) => theme.breakpoints.mobile} {
    > :nth-child(2) {
      display: none;
    }
    a {
      margin-right: 0;
    }
  }
`;
