import { ReactNode } from "react";
import styled from "@emotion/styled";

type Props = {
  children: ReactNode;
};

const ThreeColumns = ({ children, ...props }: Props): JSX.Element => {
  return <ThreeColumnsRoot {...props}>{children}</ThreeColumnsRoot>;
};

const ThreeColumnsRoot = styled.div(
  ({ theme }) =>
    `
      grid-column: 1 / span 5;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: center;
      grid-gap: 40px;

      @media (max-width: 1200px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
        grid-row: 1 / span 2;
      }
    `
);

export default ThreeColumns;
