import styled from "@emotion/styled";
import { Asset } from "contentful";

export type Props = {
  image: Asset;
  heading: string;
  detail: string;
  headingColor?: string;
  /** Percentage size to scale image */
  scale?: number;
};
/**
 * Thin borderless card
 * Listed in Contentful as IconText -- the component with that name is currently unused
 */
const IconCard = ({
  image,
  heading,
  detail,
  headingColor,
  scale = 100,
}: Props): JSX.Element => {
  return (
    <IconCardRoot headingColor={headingColor} scale={scale / 100}>
      <div className="icon">
        <img
          className={`qa-icon-original`}
          src={image?.fields?.file?.url || ""}
          alt={image?.fields?.description || ""}
        />
      </div>
      <div className="textContainer">
        {heading && <h5>{heading}</h5>}
        {detail && <p>{detail}</p>}
      </div>
    </IconCardRoot>
  );
};

type IconCardRootProps = {
  headingColor?: string;
  scale: number;
};
const IconCardRoot = styled.div<IconCardRootProps>(
  ({ headingColor, scale, theme }) =>
    `
  --icon-height: calc(${scale} * 127px);
  --root-margin: ${scale > 1 ? "0px 30px" : 0};

  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: var(--width);
  height: 100%;
  margin: var(--root-margin);
  padding-bottom: 20px

  .icon {
    display: flow-root;
    justify-content: center;
    margin: 10px 0 20px;
    flex: 1;
    align-items: center;

    img {
      width: var(--icon-height);
      height: var(--icon-height);
      object-fit: contain;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    text-align: center;
    margin: 10px 0;
  }

  .textContainer {
    padding: 0px 30px
  }

  h5 {
    ${headingColor ? `color: ${headingColor}` : ""};
  }

  @media ${theme.breakpoints.mobile} {
    padding: 30px 40px 15px

    h5 {
      font-size: 38px;
    }
  }
`
);

export default IconCard;
