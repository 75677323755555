import { memo, PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { Grid } from "../Grid";

type WrapperProps = {
  narrow: boolean;
  columns: number;
  backgroundColor?: string;
  textColor?: string;
};

const Wrapper = styled.section<WrapperProps>(
  ({ backgroundColor, textColor, columns, narrow, theme }) =>
    `
  ${textColor ? `color: ${textColor}` : ""};
  background-color: ${backgroundColor || "white"};
  text-align: center;
  justify-self: center;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;

  .inner {
    @media (min-width: 769px) {
      grid-column: ${narrow ? "2 / 10" : "1 / 11"};
      padding: 20px 0px;
    }
    padding: 24px 0;
  }

  header.section-header {
    margin: 20px 24px;

    p {
      text-align: initial;
    }
  }

  .grid {
    display: grid;
    grid-template-columns:
      [row-start] repeat(${columns || 1}, 1fr)
      [row-end];
    column-gap: 22px;

    @media (max-width: 900px) {
      grid-template-columns:
        [row-start] repeat(
          ${columns / 2 || 1},
          1fr
        )
        [row-end];
      grid-template-rows: 1fr 1fr;
    }

    @media ${theme.breakpoints.mobile} {
      grid-template-columns: [row-start] 1fr [row-end];
      grid-template-rows: 1fr;
    }

    article {
      justify-self: center;
      padding: 20px 15px;
      width: 100%;

      @media (min-width: 769px) {
        padding: 0;
      }
    }
  }
`
);

export type Props = {
  columns?: number;
  narrow?: boolean;
  backgroundColor?: string;
  textColor?: string;
  className?: string;
};

export function Section({
  children,
  columns,
  narrow,
  className,
  backgroundColor,
  textColor,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <Wrapper
      columns={columns}
      narrow={narrow}
      className={className}
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      <Grid>
        <div className="inner">{children}</div>
      </Grid>
    </Wrapper>
  );
}

export default memo(Section);
