import styled from "@emotion/styled";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import CustomerFeedbackCard from "./Card";

const ProgressPagination = ({
  testimonials,
}: {
  testimonials: any[];
}): JSX.Element => {
  return (
    <Wrapper>
      <Carousel
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        {testimonials.map((feedback, i) => (
          <CustomerFeedbackCard
            key={`customer-feedback-card-${i}`}
            quote={feedback.fields.quote}
            personsName={feedback.fields.personsName}
            image={feedback.fields.image}
          />
        ))}
      </Carousel>
    </Wrapper>
  );
};
export default ProgressPagination;

const Wrapper = styled.div`
  height: auto;
  position: relative;
  display: none;
  .carousel-root {
    width: 100% !important;

    padding-bottom: 30px !important;
  }
  .carousel.carousel-slider {
    height: 470px !important;
  }
  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .carousel .slide {
    background: transparent !important;
    color: black;
    height: 100%;
  }
  .control-dots {
    padding-left: 5px !important;
    outline: 0;
    bottom: 0px !important;
  }
  .control-dots .dot {
    box-shadow: none !important;
    background: ${({ theme }) => theme.colors.primary} !important;
    outline: 0;
  }
  @media (max-width: 1500px) {
    display: flex;
    align-items: center;
    justify-content: center;
    .carousel-root {
      width: 75% !important;
    }
  }
  @media ${({ theme }) => theme.breakpoints.mobile} {
    .carousel-root {
      width: 100% !important;
    }
  }
`;
