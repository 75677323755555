import { memo } from "react";
import CallToAction from "../CallToAction";
import BuyButton from "../BuyButton";
import { Entry } from "contentful";
import { IAction } from "../Action/types";
import { IBuyButton } from "../BuyButton/types";
import { Action } from "../Action";
import { useTheme } from "theme";
import { Section, Ul, Li, Div, Details } from "./styles";
import { PlanBenefit } from "./types";

function isBuyButtonProp(
  thing: Entry<IBuyButton | IAction>
): thing is Entry<IBuyButton> {
  const linkType =
    thing &&
    thing.sys &&
    thing.sys.contentType &&
    thing.sys.contentType.sys &&
    thing.sys.contentType.sys.id;
  return linkType === "buyButton";
}

function isAction(thing: Entry<IBuyButton | IAction>): thing is Entry<IAction> {
  const linkType =
    thing &&
    thing.sys &&
    thing.sys.contentType &&
    thing.sys.contentType.sys &&
    thing.sys.contentType.sys.id;
  return linkType === "action";
}

function PricingOptionAction({
  action,
}: {
  action: Entry<IBuyButton | IAction>;
}) {
  if (isBuyButtonProp(action)) {
    return (
      <BuyButton
        planId={action.fields.planId}
        buyText={action.fields.buyText}
        currencyCode={action.fields.currencyCode}
      />
    );
  }

  if (isAction(action)) {
    return (
      <Action
        text={action.fields.text}
        openInNewTab={action.fields.openInNewTab}
        primary={action.fields.primary}
        url={action.fields.url}
        icon={action.fields.icon}
      />
    );
  }
  return <div />;
}

type PricingOptionProps = {
  fields: {
    title: string;
    price: number;
    size: number;
    planBenefits: Entry<PlanBenefit>[];
    action: Entry<IAction>;
  };
  allBenefits: Entry<PlanBenefit>[];
};

function PricingOption({
  fields: { title, price, size, planBenefits, action },
  allBenefits,
}: PricingOptionProps) {
  const theme = useTheme();

  return (
    <Section>
      <header>
        <h2>{title}</h2>
        <h4>{size || " "}</h4>
        <h3>{price || " "}</h3>
      </header>
      <Ul>
        {allBenefits &&
          allBenefits
            .filter((benefit) => benefit.fields)
            .map(({ fields: { title, description }, sys: { id } }) => {
              return (
                <Li
                  key={id}
                  includes={
                    planBenefits.find((item) => item.sys.id === id) !==
                    undefined
                  }
                >
                  <Details values>
                    <div className="title">{title}</div>
                    <div className="description">{description}</div>
                  </Details>
                  {planBenefits.find((item) => item.sys.id === id) ? (
                    <svg
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 20 20"
                      enableBackground="new 0 0 20 20"
                      width="20px"
                    >
                      <g>
                        <circle
                          fill={theme.colors.table.included}
                          cx="10"
                          cy="10"
                          r="10"
                        />
                        <path
                          fill="#ffffff"
                          d="M7.94,14c-0.2,0-0.39-0.08-0.53-0.22L5.02,11.4c-0.29-0.29-0.29-0.77,0-1.06s0.77-0.29,1.06,0l1.86,1.86
                          l5.97-5.97c0.29-0.29,0.77-0.29,1.06,0s0.29,0.77,0,1.06l-6.5,6.5C8.33,13.92,8.14,14,7.94,14z"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 20 20"
                      enableBackground="new 0 0 20 20"
                      width="20px"
                    >
                      <g>
                        <circle
                          fill={theme.colors.table.exluded}
                          cx="10"
                          cy="10"
                          r="10"
                        />
                        <path
                          fill="#ffffff"
                          d="M11.06,10l2.3-2.3c0.29-0.29,0.29-0.77,0-1.06s-0.77-0.29-1.06,0L10,8.94l-2.3-2.3
		c-0.29-0.29-0.77-0.29-1.06,0s-0.29,0.77,0,1.06l2.3,2.3l-2.3,2.3c-0.29,0.29-0.29,0.77,0,1.06c0.15,0.15,0.34,0.22,0.53,0.22
		s0.38-0.07,0.53-0.22l2.3-2.3l2.3,2.3c0.15,0.15,0.34,0.22,0.53,0.22s0.38-0.07,0.53-0.22c0.29-0.29,0.29-0.77,0-1.06L11.06,10z"
                        />
                      </g>
                    </svg>
                  )}
                </Li>
              );
            })}
      </Ul>
      {action && <PricingOptionAction action={action} />}
    </Section>
  );
}

export type Props = {
  columns?: any[];
};

export function Table({ columns }: Props) {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "center",
      }}
    ></div>
  );
}

export default memo(Table);
