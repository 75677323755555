import { ReactNode } from "react";
import styled from "@emotion/styled";

export type Props = {
  children: ReactNode;
  reverse?: boolean;
  className?: string;
};

/**
 * A basic layout component with the first element taking up 2/3 width
 */
const TwoThirdsColumns = ({
  children,
  reverse,
  className,
}: Props): JSX.Element => {
  return (
    <TwoThirdsColumnsRoot reverse={reverse} className={className}>
      <div />
      {children}
      <div />
    </TwoThirdsColumnsRoot>
  );
};

type RootProps = Pick<Props, "reverse">;
const TwoThirdsColumnsRoot = styled.section<RootProps>(
  ({ reverse, theme }) =>
    `
      display: grid;
      grid-template-columns: 1fr ${reverse ? "5fr 3fr" : "3fr 5fr"} 1fr;
      align-items: center;
      justify-content: center;
      padding: 30px 0;

      h5 {
        color: ${theme.colors.primary};
      }

      @media (max-width: 1200px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0 auto auto 0;
        margin: 40px 24px;

        .inner {
          text-align: center;

          h3 {
            margin: 0 auto;
            max-width: 10em;
          }
        }
      }
      @media ${theme.breakpoints.mobile} {
        margin-bottom: 0;
      }
    `
);

export default TwoThirdsColumns;
