import { CSSProperties, useState } from "react";
import { domainFromUrl } from "utils/domainFromUrl";
import { Logo, Fallback, Img } from "./styles";

const transparentGif =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

type Props = {
  name: string;
  url: string;
  size?: number;
  style?: CSSProperties;
  testName?: string;
  noFallback?: boolean;
  transparent?: boolean;
};

const CompanyLogo = ({
  name,
  url,
  size,
  style,
  testName,
  noFallback,
  transparent,
}: Props): JSX.Element => {
  const [noLogo, setNoLogo] = useState(false);
  const handleSetNoLogo = () => setNoLogo(true);

  if (name === undefined) {
    return <span />;
  }

  const cleanUrl = url && domainFromUrl(url);
  const imageUrl = `https://logo.clearbit.com/${cleanUrl}?size=${
    size ? Math.floor(size / 1.25) : 44
  }`;
  return (
    <Logo
      className="company-logo"
      size={size}
      style={style}
      transparent={transparent}
      data-test={testName}
    >
      {!noFallback && (
        <Fallback
          data-testid="company-logo__fallback"
          show={noLogo || !cleanUrl}
          size={size}
        >
          {name && name.charAt(0) && name.charAt(0).toUpperCase()}
        </Fallback>
      )}
      <Img
        onError={handleSetNoLogo}
        src={cleanUrl && !noLogo ? imageUrl : transparentGif}
        alt={`${name} logo`}
      />
    </Logo>
  );
};

export default CompanyLogo;
