import { ReactNode } from "react";
import styled from "@emotion/styled";
import { RichText } from "../RichText";
import { Asset } from "contentful";
import { Document } from "@contentful/rich-text-types";

export type Props = {
  reverse?: boolean;
  body: Document;
  image: Asset;
};

/**
 * layout component with the first element taking up 2/3 width
 * one or both of the elements "overflow" the footer
 */
const FooterOverflowSection = ({
  reverse,
  image,
  body,
}: Props): JSX.Element => {
  return (
    <FooterOverflowSectionRoot reverse={reverse}>
      <div className="content">
        <div className="image-wrapper">
          <img
            className="overflow"
            src={
              image && image.fields && image.fields.file
                ? `${image.fields.file.url}?w=900`
                : ""
            }
            alt=""
          />
        </div>
        <RichText className="rich-text" body={body} />
      </div>
      <div className="fake-footer" />
    </FooterOverflowSectionRoot>
  );
};

type RootProps = Pick<Props, "reverse">;

const FooterOverflowSectionRoot = styled.section<RootProps>(
  ({ theme, reverse }) =>
    `
      --overlay-height: 180px;
      --flex-direction: ${reverse ? "row-reverse" : "row"};

      width: 100%;

      .content {
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: var(--flex-direction);
      }

      .image-wrapper {
        align-self: flex-start;
        max-width: 900px;
        width: 100%;
        margin-right: 40px;
        flex: 1;

        @media (max-width: 1800px) {
          max-width: 50vw;
        }

        img {
          width: 100%;
          margin-bottom: calc(-1 * var(--overlay-height));
        }
      }

      .rich-text {
        max-width: 450px;
        margin-right: 100px;
        flex: 0;
        flex-basis: 30%;

        h1 {
          margin-left: 0;
        }

        h5 {
          color: ${theme.colors.primary};
        }
      }

      .fake-footer {
        background-color: ${theme.colors.secondary};
        height: var(--overlay-height);
      }

      @media (max-width: 1200px) {
        --flex-direction: ${reverse ? "column-reverse" : "column"};

        .image-wrapper {
          align-self: center;
          margin-right: 0;
          max-width: 90vw;
          margin-bottom: 30px;

          img {
            margin-bottom: 0;
          }
        }

        .rich-text {
          margin-right: 0;
        }

        .fake-footer {
          display: none;
        }
      }
    `
);

export default FooterOverflowSection;
