export const URLS = {
  ARTICLES: `/insights/articles`,
  EDUCATIONAL_VIDEOS: `/insights/educational-videos`,
  RESEARCH_PAPERS: `/insights/research-papers`,
};

export const PAGE_NAMES = {
  ARTICLES: "Articles",
  EDUCATIONAL_VIDEOS: "Educational Videos",
  RESEARCH_PAPERS: "Research Papers",
};

export const PAGES = {
  ARTICLES: "ARTICLES",
  EDUCATIONAL_VIDEOS: "EDUCATIONAL_VIDEOS",
  RESEARCH_PAPERS: "RESEARCH_PAPERS",
};

export const ICONS = {
  ARTICLES: "insights-article",
  EDUCATIONAL_VIDEOS: "insights-videos",
  RESEARCH_PAPERS: "insights-research-papers",
};
