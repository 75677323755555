import styled from "@emotion/styled";
import { css } from "@emotion/react";

type LogoProps = {
  size?: number;
  transparent?: boolean;
};

export const Logo = styled.span<LogoProps>(
  ({ size, transparent }) => css`
    width: ${size || 44}px;
    height: ${size || 44}px;
    background-color: ${transparent ? "transparent" : "#fff"};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
  `
);

type FallbackProps = LogoProps & {
  show?: boolean;
};
export const Fallback = styled.span<FallbackProps>(
  ({ size, show }) => css`
    color: var(--steel);
    font-size: ${size ? size / 2 : 22}px;
    opacity: ${show ? "1" : "0"};
    width: ${size ? size : 44}px;
    text-align: center;
  `
);

export const Img = styled.img`
  width: 100%;
  border-radius: 5px;
  position: absolute;
  object-fit: contain;
`;
