import styled from "@emotion/styled";

type ElementProps = {
  primary?: boolean;
};

type Props = {
  primary: boolean;
  name: string;
  description: string;
  heading: string;
};

export const Content = ({ primary, name, heading, description }: Props) => {
  return (
    <ContentWrapper>
      <Name primary={primary}>{name}</Name>
      <HR primary={primary} />
      <Heading primary={primary}>{heading}</Heading>
      <Description primary={primary}>{description}</Description>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
`;
const Name = styled.h4<ElementProps>`
  font-size: 16px;
  margin-bottom: 10px;
  color: ${(props) =>
    props.primary
      ? props.theme.colors.fontPrimary
      : props.theme.colors.highlight};
`;
const HR = styled.span<ElementProps>`
  width: 60%;
  border: 1px solid
    ${(props) =>
      props.primary
        ? props.theme.colors.fontPrimary
        : props.theme.colors.highlight};
`;
const Heading = styled.h2<ElementProps>`
  font-size: 28px;
  font-weight: 600;
  color: ${(props) =>
    props.primary ? "#fff" : props.theme.colors.fontPrimary};
`;
const Description = styled.p<ElementProps>`
  font-size: 18px;
  color: ${(props) =>
    props.primary ? "#fff" : props.theme.colors.fontPrimary};
`;
