import { memo, ReactNode, useState } from "react";
import { Grid } from "./styles";

export type Props = {
  title: ReactNode;
  children: ReactNode;
  name: string;
  defaultStatus?: boolean;
  level: number;
};

export function Drawer({ defaultStatus, title, children, name, level }: Props): JSX.Element {
  const [drawerStatus, setDrawerStatus] = useState(defaultStatus || false);
  const toggleDrawer = () => {
    setDrawerStatus(!drawerStatus);
  };

  return (
    <Grid level={level} drawerStatus={drawerStatus}>
      <button
        onClick={toggleDrawer}
        name={name}
        role="switch"
        aria-checked={drawerStatus}
      >
        {drawerStatus ? "-" : "+"}
      </button>
      <header
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
        role="switch"
        aria-checked={drawerStatus}
        tabIndex={0}
      >
        {title}
      </header>
      <div
        style={{
          gridArea: "contents",
          display: drawerStatus ? "inline" : "none",
          borderTop: level === 1 ? "1px solid #979797" : "none",
        }}
      >
        {children}
      </div>
    </Grid>
  );
}

export default memo(Drawer);
