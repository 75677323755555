import { memo } from "react";
import ModalVideo from "../ModalVideo";

export type Props = {
  fields: {
    title: string;
    minutes: number;
    youtubeId: string;
  };
};
export function Video({ fields }: Props) {
  if (!fields) {
    return null;
  }
  const { title, minutes, youtubeId } = fields;
  return (
    <li>
      <ModalVideo youtubeId={youtubeId}>
        <div>{title}</div>
        <div style={{ color: "#ed5828", fontSize: "10px" }}>
          {minutes} <span style={{ fontSize: "8px" }}>MIN</span>
        </div>
      </ModalVideo>
    </li>
  );
}

export default memo(Video);
