import { Asset } from "contentful";
import styled from "@emotion/styled";

type StyleProps = {
  textColor: string;
  backgroundColor: string;
  backgroundImg?: Asset;
};

export const Section = styled.section<StyleProps>`
  height: calc(100vh - 120px);
  min-height: 600px;
  width: 100vw;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.2px;

  h1 {
    margin: 20px 0;
  }

  p,
  h1,
  h5,
  a {
    z-index: 1;
  }

  ${({ backgroundImg }) => {
    const backgroundImgUrl =
      backgroundImg &&
      backgroundImg.fields &&
      backgroundImg.fields.file &&
      backgroundImg.fields.file.url;
    return (
      typeof backgroundImgUrl === "string" &&
      `
        background-image: url(${backgroundImgUrl});
        background-repeat: no-repeat;
        background-size: 100% 100%;
      `
    );
  }}

  ${(props) => props.textColor && `color: ${props.textColor};`}

  ${(props) =>
    props.backgroundColor &&
    `background-color: ${
      props.backgroundColor.startsWith("#")
        ? props.backgroundColor
        : `rgba(${props.backgroundColor})`
    };`}
  @media ${({ theme }) => theme.breakpoints.tablet} {
    height: fit-content;
    padding: 25px 0;
    width: 100%;
  }
`;

type IlustrationProps = {
  src: string;
  fullHeight?: boolean;
};

export const Ilustration = styled.img<IlustrationProps>(
  ({ theme, fullHeight }) =>
    `
      --col-start: ${theme.isSelligence ? 7 : 6};

      grid-column: var(--col-start) / span 4;
      grid-row: 1;
      width: ${fullHeight ? "unset" : "100%"};
      height: auto;
      object-fit: contain;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      @media ${
        theme.isSelligence ? theme.breakpoints.laptop : theme.breakpoints.tablet
      } {
        display: none;
      }
      @media ${theme.breakpoints.laptop} {
        grid-column: 7 / span 3;
      }
    `
);

export const ContentWrapper = styled.div(
  ({ theme }) =>
    `
  --col-start: 2;

  grid-column: var(--col-start) / span 5;
  grid-row: 1;

  @media ${theme.breakpoints.laptop} {
    grid-column: 3 / span 4;
  }

  @media ${theme.breakpoints.tablet} {
    grid-column: 1 / span 10;
  }
`
);
