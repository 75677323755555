import { NextRouter } from "next/router";

export function getAsPath(router: NextRouter) {
  const path = encodeURIComponent(
    (router &&
      router.asPath &&
      `${router.locale !== router.defaultLocale ? `/${router.locale}` : ""}${
        router.asPath
      }`.split("?")[0]) ||
      ""
  );
  return path;
}
