import styled from "@emotion/styled";

const CARD_WIDTH = 432;

export const CFSectionRoot = styled.div`
  --card-width: ${CARD_WIDTH}px;
  --card-gap: 40px;

  width: 100vw;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    --card-gap: 0;
    max-width: calc(100vw - var(--card-gap));
  }

  h1 {
    text-align: center;
    margin-bottom: var(--card-gap);
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, var(--card-width));
    grid-gap: var(--card-gap);
    justify-content: center;
    overflow: hidden;
    @media (max-width: 1500px) {
      display: none;
    }
  }
`;

export const CFCardRoot = styled.div(
  ({ theme }) => `
    --background: ${theme.colors.secondary};
    --text: ${theme.colors.white};
    --outer-padding: 40px;
    --image-height: 110px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    padding: var(--outer-padding);
    border-radius: calc(var(--outer-padding) / 2);
    max-width: var(--card-width);
    height: var(--card-width);
    background-color: var(--background);
    color: var(--text);
    position: relative;
    text-align: left;
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: left !important;
    img.quote {
      width: 50px !important;
      height: 50px !important;
    }

    blockquote {
      font-style: italic;
      margin-top: calc(var(--outer-padding) / 2);
      font-size: 20px;
    }

    address {
      font-size: 33px;
      font-weight: bold;
      text-align: right;
      padding: 0;
      margin: 0;
      margin-bottom: var(--image-height);
    }

    img.author {
      width: var(--image-height);
      height: var(--image-height);
      border-radius: 10px;
      object-fit: cover;
      position: absolute;
      bottom: var(--outer-padding);
      left: var(--outer-padding);
    }
    @media (max-width: 1500px) {
      width: 90%;

      margin-left: auto;
      margin-right: auto;
      blockquote {
        font-size: 16px;
      }

      img.author {
        width: 80px !important;
        height: 80px !important;
      }
    }
  `
);
