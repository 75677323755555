import { memo } from "react";
import Link from "next/link";
import { URLS, PAGE_NAMES, PAGES, ICONS } from "../constants";
import { Li, Wrapper } from "./styles";

export type Props = {
  currentPage: string;
};
export function Nav({ currentPage }: Props) {
  const pages = [PAGES.ARTICLES, PAGES.EDUCATIONAL_VIDEOS];

  const NavItem = (page, idx) => (
    <Li key={idx} current={currentPage === URLS[page]}>
      <Link href={URLS[page]} passHref>
        <a>
          <img
            src={`/images/insights-nav/${ICONS[page]}-${
              currentPage === URLS[page] ? "white" : "grey"
            }.svg`}
            alt={PAGE_NAMES[page]}
          />{" "}
          {PAGE_NAMES[page]}
        </a>
      </Link>
    </Li>
  );

  return (
    <Wrapper>
      <nav>
        <ul>{pages.map(NavItem)}</ul>
      </nav>
    </Wrapper>
  );
}

export default memo(Nav);
