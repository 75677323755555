import styled from "@emotion/styled";

export type Props = {
  img: string;
  title: string;
  description: string;
  imgDesc: string;
};

const DetailsCard = ({ title, description, img, imgDesc }: Props) => {
  return (
    <CardWrapper>
      <CardImg src={img} alt={imgDesc ? imgDesc : ""} />
      <CardBody>
        <CardTitle>{title}</CardTitle>
        <CardText>{description}</CardText>
      </CardBody>
    </CardWrapper>
  );
};

export default DetailsCard;

const CardWrapper = styled.div`
  width: 100%;
  height: 400px;
  background: #fff;
  border-radius: 5px;

  box-shadow: -1px 3px 14px rgb(0 0 0 / 30%);
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    transform: translateY(5px);
    box-shadow: -1px 3px 8px rgb(0 0 0 / 30%);
  }
  @media (max-width: 769px) {
    margin: 20px auto;
  }
`;

const CardImg = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 5px 5px 0 0;
`;
const CardBody = styled.div`
  padding: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CardTitle = styled.h3`
  margin-top: 0px;
`;
const CardText = styled.p`
  text-align: start;
  margin: 0;
`;
