import { memo, useState, useEffect, useRef, ReactNode } from "react";
import styled from "@emotion/styled";

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;

  .modal-video-body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-video-iframe-wrap {
    width: 100%;
    height: 100%;
    max-width: 940px;
    max-height: 550px;
    position: relative;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -35px;
    right: -35px;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      content: " ";
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #fff;
      border-radius: 5px;
      margin-top: -6px;
    }
  }
`;

export type Props = {
  youtubeId: string;
  children: ReactNode;
};
export function ModalVideo({ youtubeId, children }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  const closeButtonEl = useRef(null);
  useEffect(() => {});

  const options = {
    autoplay: 1,
    cc_load_policy: 1,
    color: null,
    controls: 1,
    disablekb: 0,
    enablejsapi: 0,
    end: null,
    fs: 1,
    h1: null,
    iv_load_policy: 1,
    list: null,
    listType: null,
    loop: 0,
    modestbranding: null,
    origin: null,
    playlist: null,
    playsinline: null,
    rel: 0,
    showinfo: 1,
    start: 0,
    wmode: "transparent",
    theme: "dark",
  };

  const getUrl = (videoId) => {
    let url = "";
    for (const key in options) {
      // eslint-disable-next-line no-prototype-builtins
      if (options.hasOwnProperty(key)) {
        if (options[key] !== null) {
          url += key + "=" + options[key] + "&";
        }
      }
    }
    return `//www.youtube.com/embed/${videoId}?${url.substr(
      0,
      url.length - 1
    )}`;
  };

  return (
    <>
      <button
        style={{
          border: "none",
          backgroundColor: "none",
          display: "contents",
          color: "#ed5828",
        }}
        onClick={openModal}
      >
        {children}
      </button>
      {isOpen && (
        <Modal
          role="dialog"
          aria-label={"You just openned the modal video"}
          onClick={closeModal}
          //@ts-ignore
          tabIndex="-1"
          // onKeyDown={this.updateFocus}
        >
          <div className="modal-video-body">
            <div className="modal-video-iframe-wrap">
              <button
                className="modal-video-close-btn"
                aria-label="Close the modal by clicking here"
                ref={closeButtonEl}
                onClick={closeModal}
                // onKeyDown={this.updateFocus}
              />
              <iframe
                title="Embedded video"
                width="460"
                height="230"
                src={getUrl(youtubeId)}
                frameBorder="0"
                allowFullScreen={true}
                //@ts-ignore
                tabIndex="-1"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default memo(ModalVideo);
