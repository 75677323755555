import { useTheme } from "theme";

type Props = {
  className?: string;
};
const Quote = ({ className }: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="69.876"
      height="55.9"
      viewBox="0 0 69.876 55.9"
    >
      <path
        fill={theme.colors.quoteMark}
        d="M8.355 37.59v27.95h27.95V37.59H22.33s0-13.975 13.975-13.975V9.64s-27.95 0-27.95 27.95zM78.23 23.615V9.64s-27.95 0-27.95 27.95v27.95h27.95V37.59H64.255s0-13.975 13.975-13.975z"
        transform="translate(-8.355 -9.64)"
      />
    </svg>
  );
};
export default Quote;
