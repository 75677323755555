import { useBookDemoModalContext } from "components/BookDemoModal/hook";
import { memo } from "react";
import styled from "@emotion/styled";
import { IAction as Props } from "./types";

type AProps = {
  primary: boolean;
  color?: string;
};

const sharedStyled = ({ primary, color, theme }) =>
  `
    --border-radius: ${theme.isSelligence ? 10 : 30}px;
    --border-width: 2px;
    --accent-colour: ${primary ? "#fff" : color || "#24252a"};

    margin: 20px 0;
    background-color: ${
      primary ? theme.colors.highlight : "transparent"
    }!important;
    height: 60px;
    border-radius: var(--border-radius);
    border: ${
      primary ? "none" : "var(--border-width) solid var(--accent-colour)"
    };
    font-size: 22px;
    color: var(--accent-colour) !important;
    padding: 10px 25px;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    min-width: 230px;
    justify-content: center;

    img {
      padding-left: 15px;
      height: 30px;
    }

    &:hover {
      color: ${primary ? "#fff" : theme.colors.highlight};
    }
  `;

const A = styled.a<AProps>(sharedStyled);

export function Action({
  text,
  url,
  primary,
  icon,
  openInNewTab,
  staticAction = false,
  color,
}: Props): JSX.Element {
  const iconSrc =
    icon && icon.fields && icon.fields.file && icon.fields.file.url;
  const iconDescription = icon && icon.fields && icon.fields.description;
  const { setOpen } = useBookDemoModalContext();
  const handleButtonClick = () => {
    setOpen(true);
  };
  if (!url) {
    return null;
  }
  if (url && staticAction) {
    return (
      <A onClick={handleButtonClick} primary={primary} color={color}>
        {text}
      </A>
    );
  }
  if (url && url.substr(0, 4) === "http") {
    // Enternal links open in a new tab
    return (
      <A
        target={openInNewTab ? "_blank" : "_self"}
        href={url}
        primary={primary}
        color={color}
      >
        {text}
        {icon && <img src={iconSrc} alt={iconDescription} />}
      </A>
    );
  }
  return (
    <A href={url} primary={primary} color={color}>
      {text}
    </A>
  );
}

export default memo(Action);
