import { CFCardRoot } from "../styles";
import Quote from "./Quote";
import { Asset } from "contentful";

export type Props = { quote: string; image: Asset; personsName: string };

const CustomerFeedbackCard = ({
  personsName,
  quote,
  image,
}: Props): JSX.Element => {
  const imgSrc =
    image && image.fields && image.fields.file ? image.fields.file.url : "";
  const imgDescription =
    (image && image.fields && image.fields.description) || "";
  return (
    <CFCardRoot>
      <Quote className="quote" />
      <blockquote>{quote}</blockquote>
      {personsName && <address className="author">- {personsName}</address>}
      {image && <img className="author" src={imgSrc} alt={imgDescription} />}
    </CFCardRoot>
  );
};

export default CustomerFeedbackCard;
