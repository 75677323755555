import { Asset } from "contentful";
import styled from "@emotion/styled";

export type Props = {
  /** src of the screenshot, gif or video */
  image: Asset;
};
/**
 * Laptop image component which mounts a given screenshot of the website inside
 * Allows up to date screenshot to be used
 */
const LaptopPreview = ({ image }: Props): JSX.Element => {
  return (
    <LaptopPreviewRoot>
      <img className="laptop" src="/images/preview/laptop.png" alt="laptop" />
      <div className="screenshot-wrapper">
        {image.fields.file.url.endsWith(".mp4") ? (
          <video
            className="screenshot"
            src={image.fields.file.url}
            playsInline
            autoPlay
            loop
            muted
          />
        ) : (
          <img
            className="screenshot"
            src={image.fields.file.url}
            alt="Screenshot"
          />
        )}
      </div>
    </LaptopPreviewRoot>
  );
};

const LaptopPreviewRoot = styled.div`
  position: relative;
  max-width: 800px;

  @media (min-width: 1200px) {
    margin-left: -30px;
    margin-right: 30px;
  }

  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: none;
  }

  .laptop {
    width: 100%;
  }

  .screenshot-wrapper {
    position: absolute;
    left: 11.6%;
    top: 7%;
    width: 77.4%;
    height: 74.5%;
    overflow: hidden;

    img,
    video {
      width: 100%;
    }
  }
`;

export default LaptopPreview;
