import { memo } from "react";
import styled from "@emotion/styled";
import { CONTENT_TYPES } from "../../../lib/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Drawer } from "components/Contentful/Drawer";
import { Video } from "../Video";

const Ul = styled.ul`
  padding-bottom: 29px;

  li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #979797;
    padding: 8px 0;
    font-size: 12px;

    &:last-child {
      border-bottom: none;
    }
  }
`;

const Body = styled.div`
  padding: 0 0 19px;
  max-width: 40em;
`;

export type Props = {
  fields: {
    title: string;
    body;
    videos;
    level: number;
    initiallyOpen;
  };
  sys: any;
};

export function Group(
  { fields: { title, body, videos, level, initiallyOpen }, sys }: Props,
  idx: number
) {
  const v =
    videos &&
    videos.filter(
      (item) => item?.sys.contentType.sys.id === CONTENT_TYPES.EDUCATIONAL_VIDEO
    );

  const subDrawers =
    videos &&
    videos.filter(
      (item) =>
        item?.sys.contentType.sys.id === CONTENT_TYPES.EDUCATIONAL_VIDEOS_GROUP
    );

  const videoText = (v) => {
    return v && v.length
      ? `${v.length} video${
          v.length !== 1 ? "s" : ""
        } available | Total time: ${v.reduce(
          (prev, curr) =>
            prev + curr.fields && curr.fields.minutes ? curr.fields.minutes : 0,
          0
        )} MIN`
      : ``;
  };

  return (
    <Drawer
      defaultStatus={initiallyOpen}
      key={`drawer-${sys ? sys.id : idx}`}
      name={`drawer-${sys ? sys.id : idx}`}
      level={level}
      title={
        <>
          {level === 1 ? <h3>{title}</h3> : <h4>{title}</h4>}
          <div>{videoText(v)}</div>
        </>
      }
    >
      {body && <Body>{documentToReactComponents(body)}</Body>}
      {v && typeof v.length === "number" && v.length > 0 && (
        <Ul>{v.map(Video)}</Ul>
      )}
      {subDrawers &&
        subDrawers.map((drawer, idx) => (
          <Group key={idx} fields={drawer.fields} sys={sys} />
        ))}
    </Drawer>
  );
}

export default memo(Group);
