/* eslint-disable react/display-name */
import { memo } from "react";
import styled from "@emotion/styled";
import { RichText } from "../RichText";
import { Asset } from "contentful";
import { Document } from "@contentful/rich-text-types";

type SectionProps = Pick<Props, "backgroundColor" | "imageOverflowPercent">;

const Section = styled.section<SectionProps>(
  ({ theme, backgroundColor, imageOverflowPercent }) =>
    `
  padding: 0 140px;
  @media ${theme.breakpoints.tablet} {
    padding: 0;
  }

  background-color: ${backgroundColor || theme.colors.white};
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 0;
 
  .content {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    ${theme.isSelligence ? "width: 95%" : ""};

    @media ${theme.breakpoints.tablet} {
      justify-content: center;
    }
  }

  .image-wrapper {
    flex: 1;
    max-width: 100%;
    @media ${theme.breakpoints.laptop} {
      flex: none;
    }
  }

  img.overflow {
    max-width: 100%;
    margin-top: ${-1 * imageOverflowPercent || 0}%;
    margin-bottom: ${(-1 * imageOverflowPercent) / 2 || 0}%;

    ${
      theme.isSelligence
        ? `
      width: 600px;

      @media ${theme.breakpoints.tablet} {
        padding: 20px;
      }
    `
        : ""
    };

    @media ${theme.breakpoints.laptop} {
      margin-bottom: 0;
    }
  }

  .rich-text {
    margin: 0 16px;
    flex: 1;
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;

    h1 {
      margin-left: 0;
    }
    
    h2 {
      max-width: 15em;
    }

    h5 {
      color: ${theme.colors.primary};
    }

    @media ${theme.breakpoints.laptop} {
      margin: 0 24px;
      text-align: center;
      &::before {
        content: none;
      } 
    }
  }
`
);

export type Props = {
  body: Document;
  imageOverflowPercent: number;
  backgroundColor?: string;
  image?: Asset;
};

export function ImageOverflowSection({
  body,
  image,
  imageOverflowPercent,
  backgroundColor,
}: Props): JSX.Element {
  return (
    <Section
      imageOverflowPercent={imageOverflowPercent}
      backgroundColor={backgroundColor}
    >
      <div className="content">
        <div className="image-wrapper">
          <img
            className="overflow"
            src={
              image && image.fields && image.fields.file
                ? `${image.fields.file.url}?w=650`
                : ""
            }
            alt=""
          />
        </div>
        <RichText className="rich-text" body={body} />
      </div>
    </Section>
  );
}

export default memo(ImageOverflowSection);
