import { requestConfig } from "config/apiResource";
import { memo, useReducer } from "react";
import { useForm } from "react-hook-form";
import { Form } from "./styles";
import request from "utils/request";
import { useTheme } from "theme";

export type ReducerState = {
  loading?: boolean;
  success?: boolean;
  error?: string;
};
export const initialState: ReducerState = {
  success: false,
};
export type ReducerAction =
  | { type: "request" }
  | { type: "success" }
  | { type: "failed"; error: Error };

export function reducer(
  state: ReducerState,
  action: ReducerAction
): ReducerState {
  switch (action.type) {
    case "request":
      return { loading: true, success: false, error: null };
    case "success":
      return { success: true, loading: false };
    case "failed":
      return { loading: false, error: action.error.message };
    default:
      return state;
  }
}

export type ContactUsValues = {
  first_name: string;
  last_name: string;
  email: string;
  company: string;
  phone?: string;
  message?: string;
  contact_us_marketing?: boolean;
};

export function ContactUsForm(): JSX.Element {
  const { handleSubmit, register, errors } = useForm<ContactUsValues>({
    defaultValues: {
      contact_us_marketing: true,
    },
  });
  const [state, dispatch] = useReducer(reducer, initialState);
  const { productName } = useTheme().meta;

  async function onSubmit(values: ContactUsValues) {
    dispatch({ type: "request" });
    try {
      const res = await request(requestConfig.contactUsSubmission(values));
      if (res.status === 200) {
        dispatch({ type: "success" });
      } else {
        throw new Error(`Error ${res.status} - ${res["message"]}`);
      }
    } catch (error) {
      dispatch({ type: "failed", error });
    }
  }

  return state.success ? (
    <div style={{ gridColumn: "1 / 3" }}>
      Thank you. One of the team will contact you shortly
    </div>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {state.loading && <div className="loading">loading...</div>}

      <fieldset disabled={state.loading}>
        <label htmlFor="first_name">First name*</label>
        {errors.first_name && errors.first_name.message}
        <input
          type="text"
          name="first_name"
          className={errors.first_name ? "error" : ""}
          ref={register({
            required: "Required",
          })}
        />

        <label htmlFor="last_name">Last Name*</label>
        {errors.last_name && errors.last_name.message}
        <input
          type="text"
          name="last_name"
          className={errors.last_name ? "error" : ""}
          ref={register({ required: "Required" })}
        />

        <label htmlFor="company">Company*</label>
        {errors.company && errors.company.message}
        <input
          type="text"
          name="company"
          className={errors.company ? "error" : ""}
          ref={register({ required: "Required" })}
        />

        <label htmlFor="email">Email Address*</label>
        {errors.email && errors.email.message}
        <input
          type="text"
          name="email"
          className={errors.email ? "error" : ""}
          ref={register({
            required: "Required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "invalid email address",
            },
          })}
        />

        <label htmlFor="phone">Contact Number</label>
        <input
          className={errors.phone ? "error" : ""}
          type="text"
          name="phone"
        />
      </fieldset>

      <fieldset disabled={state.loading}>
        <label htmlFor="message">Your Message*</label>
        <textarea
          className={errors.message ? "error" : ""}
          name="message"
          ref={register({ required: "Required" })}
        />
      </fieldset>

      <div className="complete">
        <div>
          Sign up to the {productName} email list?{" "}
          <input type="checkbox" name="contact_us_marketing" ref={register} />
        </div>
        <div>
          <input type="submit" />
        </div>
        {state.error}
      </div>
    </Form>
  );
}

export default memo(ContactUsForm);
