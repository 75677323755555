import { memo } from "react";
import styled from "@emotion/styled";
import Action from "../Action";
import { Asset } from "contentful";

const Section = styled.section(
  ({ theme }) => `
    justify-self: center;
    color: ${theme.colors.highlight};
    background-color: #fff;

    blockquote {
      color: #000;
      font-size: 24px;
      font-weight: 600;
      max-width: 27em;
      margin: 24px 20px;

      @media (min-width: 769px) {
        margin: 24px 0;
      }
    }

    .person-name {
      font-size: 18px;
    }
  `
);

export type Props = {
  quote: string;
  personsName: string;
  jobTitle: string;
  action?: {
    fields: {
      text: string;
      openInNewTab: boolean;
      primary: boolean;
      url: string;
      icon: Asset;
    };
  };
};
export function Testimonial({ quote, personsName, jobTitle, action }: Props) {
  return (
    <Section>
      <blockquote>{quote}</blockquote>
      <div className="person-name">{personsName}</div>
      <div className="job-title">{jobTitle}</div>
      {action && action.fields && <Action {...action.fields} />}
    </Section>
  );
}

export default memo(Testimonial);
