/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "store/auth/actions";
import { addSnackbarMessage } from "store/notices/actions";
import { fetchSeats } from "store/seats/actions";
import { getUserId } from "store/auth/selectors";
import Link from "next/link";
import request from "utils/request";
import { requestUrl } from "utils/api-request";
import { IBuyButton as Props, CurrencyCode } from "./types";
import { getParameterByName } from "utils/query-helper";
import { useRouter } from "next/router";
import { getAsPath } from "utils/get-as-path";
import { CHARGEBEE_SITE } from "../../../env";

function BuyButton({ planId, buyText, currencyCode }: Props): JSX.Element {
  const router = useRouter();
  const shouldBeOpen = useCallback(() => {
    const action = getParameterByName("a", router.asPath) || "";
    const actionPlan = action.replace("open_", "").toLowerCase();
    const buttonPlan = planWithCurrency(planId, currencyCode);
    return actionPlan === buttonPlan;
  }, [currencyCode, planId, router.asPath]);

  const [chargeBeeOpen, setChargeBeeOpen] = useState(shouldBeOpen);
  const [errorText, setErrorText] = useState<string>();
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const [hostedPageObject, setHostedPageObject] = useState<unknown>();

  function planWithCurrency(planId: string, currencyCode: CurrencyCode) {
    return `${planId}${
      currencyCode && currencyCode !== "GBP" ? `-${currencyCode}` : ``
    }`.toLowerCase();
  }

  useEffect(() => {
    async function fetchHostedPage(planId: string) {
      let response: { data: unknown };
      try {
        response = await request({
          url: requestUrl({
            service: "v2-chargebee",
            action: "generate_checkout_existing_url",
            query: `plan_id=${planWithCurrency(planId, currencyCode)}`,
          }),
          options: {
            method: "POST",
            body: JSON.stringify({}),
          },
          auth: true,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        setErrorText("Please call us");
      }
      if (response && response.data) {
        setHostedPageObject(response.data);
        return Promise.resolve();
      } else {
        setHostedPageObject(null);
        return Promise.reject();
      }
    }
    if (userId && userId !== "guest") {
      fetchHostedPage(planId).then(() => {
        if (shouldBeOpen()) {
          setChargeBeeOpen(true);
        }
      });
    }
  }, [currencyCode, planId, shouldBeOpen, userId]);

  useEffect(() => {
    function handleSuccessfulAuth0(_err: unknown, result: unknown) {
      dispatch(updateUser(result));
      dispatch(fetchSeats(userId));
    }
    function refreshAuth0() {
      const redirectUri = `${window.location.protocol}//${window.location.host}`;
      window.webAuth.checkSession(
        {
          redirectUri,
        },
        handleSuccessfulAuth0
      );
    }
    function handleSuccessfulCheckout() {
      dispatch(
        addSnackbarMessage({
          message:
            "Your checkout has been successful. It can take up to a minute for the changes to be applied",
        })
      );
      dispatch(fetchSeats(userId));
      setHostedPageObject(null);
      setTimeout(refreshAuth0, 18000);
    }
    if (hostedPageObject && window && window.Chargebee && chargeBeeOpen) {
      const cb = window.Chargebee.init({
        site: CHARGEBEE_SITE,
      });
      cb.openCheckout({
        hostedPage: () => Promise.resolve(hostedPageObject),
        close: () => setChargeBeeOpen(false),
        success: handleSuccessfulCheckout,
      });
    }
  }, [hostedPageObject, chargeBeeOpen, dispatch, userId]);

  if (errorText) {
    return <div className="button-placeholder">{errorText}</div>;
  }

  if (!userId || userId === "guest") {
    return (
      <Link
        passHref
        href={`/signup?r=${getAsPath(router)}&a=open_${planWithCurrency(
          planId,
          currencyCode
        )}`}
      >
        <a>{buyText}</a>
      </Link>
    );
  }

  return <button onClick={() => setChargeBeeOpen(true)}>{buyText}</button>;
}

export default BuyButton;
