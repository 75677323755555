import styled from "@emotion/styled";

type LiProps = {
  current?: boolean;
};
export const Li = styled.li<LiProps>(
  ({ current, theme }) => `
    display: flex;
    align-items: center;
    padding: 0 7px;
    background-color: ${current ? theme.colors.primary : "transparent"};
    height: 54px;
    border-radius: 27px;
    border: ${current ? "none" : "1px solid #c5c5c5"};
    margin-bottom: 16px;
    @media (min-width: 769px) {
      border: none;
      border-radius: 12.5px;
      height: 26px;
    }
    a {
      display: flex;
      align-items: center;
      padding-right: 10px;
      color: ${current ? "white" : "black"};
      width: 100%;
    }
  `
);

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  margin: 22px 0;
  @media (min-width: 769px) {
    margin: 30px 0 10px;
  }
  nav {
    display: flex;
    padding: 0 15px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    @media (min-width: 769px) {
      width: 290px;
      flex-direction: row;
    }
  }

  img {
    margin-right: 10px;
    margin-left: -3px;
    width: 46px;
    @media (min-width: 769px) {
      width: 18px;
    }
  }

  .search {
    width: 100%;
    max-width: 489px;
    margin: 27px 0;
    position: relative;

    input[type="text"] {
      width: 100%;
      border-radius: 17px;
      border: solid 0.5px #979797;
      height: 34px;
      padding: 0 21px;
    }

    input[type="button"] {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
    }
  }
`;
