import styled from "@emotion/styled";

export const Form = styled.form(
  ({ theme }) => `
    display: contents;
    text-align: left;

    .loading {
      grid-column: 1 / 3;
    }

    fieldset {
      position: relative;
      border: none;
      height: 100%;
      padding: 0 15px;
      &:disabled {
        opacity: 0.3;
      }

      @media (min-width: 769px) {
        padding: 0;
      }
    }

    input[type="text"],
    textarea,
    label {
      width: 100%;
    }

    label {
      font-size: 12px;
      letter-spacing: -0.17px;
    }

    input,
    textarea {
      border-radius: 22px;
      border: solid 0.5px rgb(151, 151, 151);
      margin: 8px 0;
      &.error {
        background-color: red;
      }
    }

    input[type="text"] {
      height: 44px;
      padding: 0 22px;
    }

    input[type="submit"] {
      margin: 20px 0;
      background-color: ${theme.colors.highlight};
      border-radius: 17px;
      border: none;
      font-size: 12px;
      color: #fff;
      padding: 10px 15px;
      display: inline-block;
    }

    textarea {
      height: calc(100% - 33px);
      padding: 22px;
    }

    .complete {
      grid-column: row-start / row-end;
      text-align: center;
    }
  `
);
