import styled from "@emotion/styled";

type GridProps = {
  level: number;
  drawerStatus?: boolean;
};

export const Grid = styled.section<GridProps>(
  (props) => `
    display: grid;
    align-items: center;
    text-align: left;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: auto auto;
    grid-template-areas: ${
      props.level === 1
        ? `"title title title title title title title title title status"
         "contents contents contents contents contents contents contents contents contents contents";`
        : `"status title title title title title title title title title"
         ". contents contents contents contents contents contents contents contents contents";`
    };
    grid-column-gap: 26.6px;
    border-bottom: ${
      props.level === 1 && props.drawerStatus ? "none" : "1px solid #979797"
    };

    padding-bottom: ${props.level === 1 ? "10px" : "0"};
    margin-bottom: ${props.level === 1 ? "10px" : "0"};
    padding: 0 22px;

    @media (min-width: 769px) {
      padding: 0;
    }

    button {
      grid-area: status;
      margin: 14px;
      width: 29px;
      height: 29px;
      border-radius: 50%;
      border: 1px solid ${props.theme.colors.highlight};
      color: ${props.theme.colors.highlight};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    header {
      grid-area: title;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0;
    }
  `
);
