import { useContext, useEffect } from "react";
import { BookDemoModalContext, BookDemoModalContextType } from "./context";

/**
 *  This hook controls the state of the book demo modal
 * @returns BookDemoModalContextType
 */

export const useBookDemoModalContext = (): BookDemoModalContextType => {
  const { open, setOpen } = useContext(BookDemoModalContext);

  useEffect(() => {
    if (typeof setOpen === "undefined") {
      throw new Error(
        "Oops! looks like you forgot to use this hook within in its provider! Please wrap the component in the BookDemoModalContextProvider"
      );
    }
  }, [setOpen]);

  return {
    open,
    setOpen,
  };
};
