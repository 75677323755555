import { requestUrl } from "utils/api-request";

export type LatestNews = {
  id: string;
  summary: string;
  published: string;
  title: string;
  imgUrl: string;
  companyName: string;
};

export async function latestNewsFetcher(...args: [string]) {
  const service = args[0].split("/")[1] as TalentTickerServices;
  const url = requestUrl({
    service,
    action: "search",
  });
  const bodyNews = {
    desc: "timestamp_published",
    limit: 5,
    skip: 0,
    tab: "news",
    input: [],
  };
  const leadsOptions = {
    method: "POST",
    body: JSON.stringify({ ...bodyNews }),
  };
  try {
    const leadsRes = await fetch(url, leadsOptions);
    const leads = await leadsRes.json();
    const latestNews = leads.data.results.documents.map((news) => ({
      id: news.event_id,
      summary: news.summary,
      title: news.title,
      published: news.timestamp_published,
      imgUrl: news.company_details.urls.homepage,
      companyName: news.company_details.name,
    }));

    return latestNews as LatestNews[];
  } catch {
    return [];
  }
}
